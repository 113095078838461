exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-projects-catch-them-js": () => import("./../../../src/pages/projects/catch-them.js" /* webpackChunkName: "component---src-pages-projects-catch-them-js" */),
  "component---src-pages-projects-citizenship-test-js": () => import("./../../../src/pages/projects/citizenship-test.js" /* webpackChunkName: "component---src-pages-projects-citizenship-test-js" */),
  "component---src-pages-projects-clarity-js": () => import("./../../../src/pages/projects/clarity.js" /* webpackChunkName: "component---src-pages-projects-clarity-js" */),
  "component---src-pages-projects-driving-test-js": () => import("./../../../src/pages/projects/driving-test.js" /* webpackChunkName: "component---src-pages-projects-driving-test-js" */),
  "component---src-pages-projects-family-channels-js": () => import("./../../../src/pages/projects/family-channels.js" /* webpackChunkName: "component---src-pages-projects-family-channels-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-nfb-film-app-js": () => import("./../../../src/pages/projects/nfb-film-app.js" /* webpackChunkName: "component---src-pages-projects-nfb-film-app-js" */),
  "component---src-pages-projects-novie-js": () => import("./../../../src/pages/projects/novie.js" /* webpackChunkName: "component---src-pages-projects-novie-js" */),
  "component---src-pages-projects-top-best-youtube-channels-js": () => import("./../../../src/pages/projects/top-best-youtube-channels.js" /* webpackChunkName: "component---src-pages-projects-top-best-youtube-channels-js" */),
  "component---src-pages-projects-turn-off-tv-js": () => import("./../../../src/pages/projects/turn-off-tv.js" /* webpackChunkName: "component---src-pages-projects-turn-off-tv-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

